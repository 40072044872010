<template>
    <div class="container">
        <h1>Area executar:</h1>
        <!-- <b-button class="m-1" @click="vida()" variant="outline-primary" style="width: fit-content">Solicitar</b-button> -->
        <!-- <form action="https://www.overleaf.com/docs" method="post" target="_blank">
            <textarea rows="8" cols="60" name="snip">
                \documentclass[12pt]{article}
                \usepackage[english]{babel}
                \usepackage[utf8x]{inputenc}
                \usepackage{lipsum}
                \usepackage{amsmath}
                \usepackage[english]{babel}
                \usepackage{blindtext}
                \usepackage{tikz}
                \begin{document}
                \title{Untitled}

                \tableofcontents
                \maketitle
                \newpage
                \section{Apresentação do Curso}
                \subsection{Justificativa da escolha}
                \lipsum[7]

                \subsection{Objetivos Gerais}
                \blindtext[5]
                \blindlist{itemize}[7]
                \blindlist{description}[7]

                \end{document}
            </textarea>
            <input type="submit" value="Open in Overleaf"> 
        </form> -->

        <ul>
            <li v-for="curso in cursos_ead_dados" :key="curso.id">
                {{curso.nome_curso}}
            </li>
        </ul>
        
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    data() {
        return {
            formularios: '',
            bibliografias: '',
            cursos_ead_dados: [],
            cursos_ead: ['s15ZN1jBHkt6VObU8lzU'],
        }
    },
    methods:{
        solicitarEad(){
            // console.log(this.cursos_ead) 
            // db.collection("/nadir")
            // .get()
            // .then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => {
            //         let dados_formulario = doc.data()
            //         if(doc.data().oferta_ead){
            //             dados_formulario['id'] = doc.ref.id
            //             this.cursos_ead.push(dados_formulario)
            //         }
                    
            //     });
            // })
            // .catch((error) => {
            //     console.log("Error getting documents: ", error);
            // });
            
            this.cursos_ead.forEach(x => this.getCurso(x))
        },

        getCurso(id_curso){
            let dados_curso
            db.collection("/nadir").doc(id_curso)
            .get()
            .then((doc) => {
                dados_curso = doc.data()
                // console.log(dados_curso)
                this.cursos_ead_dados.push(dados_curso)
                // Mudando nome
                dados_curso.nome_curso = dados_curso.nome_curso + ' - EAD'
                console.log('Registrando ' + dados_curso.nome_curso, dados_curso)
                // Adicionando novo:
                db.collection("/nadir")
                .add(dados_curso)
                .then(() => {
                    console.log('Feito')
                })
                .catch((error) => {
                    console.error(`Erro ao cadastrar o curso`, dados_curso, error);
                });
            })
        },

        duplicar(){
            let dados_curso
            db.collection("/nadir").doc('FyfSplxRO7XlF5DXIzuQ')
            .get()
            .then((doc) => {
                dados_curso = doc.data()
                dados_curso.nome_curso = "Técnico em Reciclagem - EAD 2"
                console.log(dados_curso)
                db.collection("/nadir")
                .add(dados_curso)
                .then(() => {
                    console.log('Feito')
                })
                .catch((error) => {
                    console.error(`Erro ao cadastrar o curso`, error);
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        writeNested(){
            let id_item
            let id_competencia
            let array = ['livro 1', 'livro 2']

            db.collection("/cities")
            .doc('nbZUzGznydKPBlIq3PYJ')
            .update(
                {[`formulario_especifico.${id_item}[${id_competencia}].bibliografia_basica`]: array}
            )
            .then(() => {
                console.log("Document: successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: " , error);
            });
        },

        addCursoTest(){
            // db.collection("/cities")
            // .doc(doc_name.toString())
            // .set(doc_value) // obj
            // .then(() => {
            //     console.log("Document: "+ doc_name +" successfully updated!");
            // })
            // .catch((error) => {
            //     alert('Erro, verifique o console')
            //     console.error("Error updating document: ", doc_name , error);
            // });

            // let formulario_especifico= {
            //         // 1
            //         item_1_3: '',
            //         item_1_4: '',
            //         item_1_5: '',
            //         item_1_5_1: '',
            //         item_1_5_2: '',
            //         // 2
            //         item_2: '',
            //         item_2_1: '',
            //         item_2_2: '',
            //         // 3
            //         item_3: '',
            //         item_3_1: '',
            //         // 4
            //         item_4: '',
            //         item_4_1: '',
            //         item_4_2: '',
            //         item_4_2_1: [],
            //         item_4_3: '',
            //         item_4_4: '',
            //         item_4_5: '',
            //         item_4_6: '',
            //         item_4_6_1: '',
            //         item_4_6_2: '',
            //         item_4_6_3: '',
            //         item_4_7: '',
            //         item_4_8: '',
            //         item_4_9: '',
            //         item_4_9_1: '',
            //         item_4_9_2: '',
            //         item_4_9_3: '',
            //         item_4_10: '',
            //         item_5: '',
            //         item_5_1: '',
            //         item_5_2: '',
            //         item_5_3: '',
            //         item_5_4: '',
            //         item_5_5: '',
            //         item_5_6: '',
            //         item_5_7: '',
            //         item_6: '',
            //         item_6_1: [],
            //         item_6_2: [],
            //         item_6_3: '',
            //         item_6_4: [],
            //         item_7: '',
            //         item_7_1: '',
            //         item_7_2: '',
            //         item_8: '',
            //         item_9: '',
            //         item_9_1: '',
            //         item_9_2: '',
            //         item_9_3: '',
            //         item_10: '',
            //     }

            //     db.collection("/cities")
            //     .add({
            //             formulario_especifico: formulario_especifico, 
            //         })
            //     .then(() => {
            //     })
            //     .catch((error) => {
            //         console.error(`Erro ao cadastrar o curso`, error);
            //     });
        },

        firebaseCampus(){
            let campus_array = [
                {nome: 'Vergueiro', id: 'campus_vg'},
                {nome: 'Vila Prudente', id: 'campus_vp'},
                {nome: 'Vila Maria', id: 'campus_vm'},
                {nome: 'Santo Amaro', id: 'campus_sa'},
                {nome: 'Memorial', id: 'campus_mm'},
                {nome: 'Guarulhos', id: 'campus_gr'},
                {nome: 'Osasco', id: 'campus_os'},
                {nome: 'Mauá', id: 'campus_ma'},
                {nome: 'Bauru', id: 'campus_ba'},
                {nome: 'São Bernado', id: 'campus_sb'},
            ]
            
            campus_array.forEach(x => this.addCampus(x.id, x))

            
        },

        vida(){
            db.collection("/backup2506")
            .doc('formulario_geral')
            .set(
                {documento_completo: this.todos_dados}
            )
            .then(() => {
                console.log("Document: successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: " , error);
            });
            
        },

        addCampus(doc_name, doc_value){
            console.log(doc_name,doc_value)
            db.collection("/campus_uninove")
            .doc(doc_name.toString())
            .set(doc_value) // obj
            .then(() => {
                console.log("Document: "+ doc_name +" successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", doc_name , error);
            });
        }

    },
    
}
</script>